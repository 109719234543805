var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.idPopupPreviewIssuePdf,
      "size": "1024px",
      "centered": "",
      "header-bg-variant": "white",
      "body-class": "px-1 pt-0 pb-1",
      "hide-footer": "",
      "title-class": "h2 header-class text-dark",
      "title": _vm.$t('invoice.detail')
    }
  }, [_c('object', {
    attrs: {
      "data": _vm.pdfUrl,
      "type": "application/pdf",
      "width": "996",
      "height": "600"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }