<template>
  <b-modal
    :id="idPopupPreviewIssuePdf"
    size="1024px"
    centered
    header-bg-variant="white"
    body-class="px-1 pt-0 pb-1"
    hide-footer
    title-class="h2 header-class text-dark"
    :title="$t('invoice.detail')"
  >
    <object
      :data="pdfUrl"
      type="application/pdf"
      width="996"
      height="600"
    />
    <!--  -->
  </b-modal>
</template>

<script>
export default {
  name: 'PopupPreviewIssueInvoicePdf',

  props: {
    idPopupPreviewIssuePdf: {
      type: String,
      default: 'id-invoice-popup-preview-issue-pdf',
    },
    pdfUrl: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.modal-dialog.modal-1024px {
  width: 1024px !important;
  max-width: 1024px !important;
}
</style>
